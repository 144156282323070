import React from 'react';
import { getSingleText } from '../../../../common/utils';
import { ReuseableFieldNameWrapper } from './FieldMappingViewStyled';
import { DetachFieldLink } from '../components/DetachFieldLink/DetachFieldLink';
import ReuseableFieldName from '../components/ReuseableFieldName/ReuseableFieldName';

export default function FieldMappingView({ fieldState, baseTextPath, customStyle = {}, explicitFieldDetach, isAnEcBrokenField }) {
  let ecKeySubTitle = null;
  let ecFriendlyNameSubTitle = null;

  const BROKEN_FIELD_TEXT = getSingleText(`${baseTextPath}brokenFieldText`);

  if (isAnEcBrokenField) {
    ecKeySubTitle = BROKEN_FIELD_TEXT;
    ecFriendlyNameSubTitle = BROKEN_FIELD_TEXT;
  } else {
    if (fieldState && fieldState.ecKey) {
      ecKeySubTitle = fieldState.ecKey;
    }
    if (fieldState && fieldState.ecFriendlyName) {
      ecFriendlyNameSubTitle = fieldState.ecFriendlyName;
    }
  }

  return (
    <div>
      <ReuseableFieldNameWrapper>
        <ReuseableFieldName
          title={getSingleText(`${baseTextPath}reportingFieldName`)}
          subTitle={ ecKeySubTitle }
          customStyle={customStyle} />
      </ReuseableFieldNameWrapper>
      <div style={{ marginBottom: 14 }}>
        {
          ecFriendlyNameSubTitle && <ReuseableFieldName
            title={getSingleText(`${baseTextPath}reusableFieldName`)}
            subTitle={ ecFriendlyNameSubTitle }
            customStyle={customStyle} />
        }
      </div>
      <DetachFieldLink
        explicitFieldDetach={explicitFieldDetach}
        baseTextPath={baseTextPath}
        hasEcKey={Boolean(fieldState && fieldState.ecKey)}
      />
    </div>
  );
}
