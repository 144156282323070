import React, { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import Accordion from '@mdigital/components/dist/components/Accordion';
import DigTitle from '@mdigital/components/dist/components/Title';
import EmbeddedTargetingMetadata from '../Targeting/Metadata/EmbeddedTargetingMetadata';
import EmbeddedTargetingSession from '../Targeting/Session/EmbeddedTargetingSession';
import PropTypes from 'prop-types';
import {
  INITIAL_INVITE_ON_DECLINE,
  INITIAL_TARGETING_URL,
  INITIAL_FREQUENCY,
  INITIAL_NUMBER_OF_VISITS,
  INITIAL_NUMBER_OF_PAGES_VIEWED,
  INITIAL_INVITE_ON_SUBMITTED,
  INITIAL_GENERIC_RULE,
  DESKTOP,
  RULES_NAME,TEXT_PATH
} from '../utils/constants';
import withProvider from '../../../hoc/ProviderHoc';
import { connect, useDispatch } from 'react-redux';
import { fetchAvailableFormRules } from '../../../../redux/actions/rule-engine.actions';
import {
  isFrequencyValueValid,
  isNumberPositive,
  mapAutoSuggestOptionToValue
} from '../utils/helperFunctions';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { getSingleText } from '../../../../common/utils';

const additionalDigLabelStyle = {
  fontSize: 14,
  color: '#8e8e93',
  fontWeight: 800,
  letterSpacing: 0.17,
};

function EmbeddedTargeting({
  formData,
  setFormDataRules,
  setPageElementId,
  setFormError,
  isReadOnlyMode,
}) {
  const [rules, setRules] = useState(null);
  const [pageElementID, setPageElementID] = useState('');
  const [shouldShowIncludedError, setShouldShowIncludedError] = useState(false);
  const [shouldShowExcludedError, setShouldShowExcludedError] = useState(false);

  const previousRef = useRef({});
  const dispatch = useDispatch();

  useEffect(() => {
    const updatePageId = (pageElementId) => {
      let id = pageElementId;
      if (id === null) {id = '';}
      setPageElementID(id);
    };
    if (Object.keys(formData).length > 0) {
      updatePageId(formData.pageElementId);
      setRules(formData.rules);
      dispatch(fetchAvailableFormRules(formData.formId));
    }
  }, [formData]);

  useEffect(() => {
    setFormError(shouldShowExcludedError || shouldShowIncludedError);
  }, [shouldShowExcludedError, shouldShowIncludedError]);

  useEffect(() => {
    setFormDataRules({ rules });
  }, [rules]);

  useEffect(() => {
    setPageElementId(pageElementID);
  }, [pageElementID]);

  //save previous props to re-value when checkbox is true
  function handleCheckBoxToggle(state, param, optionalValue = null) {
    if (!state) {
      previousRef.current = {
        ...previousRef.current,
        [param]: optionalValue ? optionalValue : getNestedProperty(rules, param, ''),
      };
      const newRules = { ...rules };
      delete newRules[param];
      setRules(newRules);
    }
    if (state && previousRef.current[param]) {
      const newRules = { ...rules, [param]: previousRef.current[param] };
      delete previousRef.current[param];
      setRules(newRules);
    }
  }

  /// metadata based targeting

  function onTargetDevices(newDevice) {
    let currentDevices = [...rules.DeviceTypes.params.devices];
    if (currentDevices.includes(newDevice)) {
      currentDevices = currentDevices.filter((item) => item !== newDevice);
      //default device as desktop
      if (currentDevices.length === 0) {
        currentDevices = [DESKTOP];
      }
    } else {
      currentDevices = [...currentDevices, newDevice];
    }
    const newRules = { ...rules };
    newRules.DeviceTypes.params.devices = [...currentDevices];
    setRules(newRules);
  }

  function handleTargetUrls({ value, param }) {
    const isWithSpaces = value && value.trim().includes(' ');
    if(param === 'included') {
      setShouldShowIncludedError(isWithSpaces);
    }
    if(param === 'excluded') {
      setShouldShowExcludedError(isWithSpaces);
    }
    const currentTargetingUrl = {
      ...getNestedProperty(
        rules,
        RULES_NAME.TARGETING_URL,
        INITIAL_TARGETING_URL
      ),
    };
    currentTargetingUrl.params[param] = value;
    const newRules = { ...rules, TargetingUrl: currentTargetingUrl };
    setRules(newRules);
  }

  function onGenericRuleChange(name, ruleId) {
    const currentGenericRule = {
      ...getNestedProperty(
        rules,
        RULES_NAME.GENERIC_RULE,
        INITIAL_GENERIC_RULE
      ),
    };
    currentGenericRule.params.name = name;
    currentGenericRule.params.ruleId = ruleId;
    const newRules = { ...rules, GenericRule: currentGenericRule };
    setRules(newRules);
  }

  /// session based targeting

  function handleFrequencyChange({ frequency }) {
    if (!isFrequencyValueValid(frequency)) {return;}
    const currentFrequency = {
      ...getNestedProperty(rules, RULES_NAME.FREQUENCY, INITIAL_FREQUENCY),
    };
    currentFrequency.params.frequency = parseInt(frequency);
    const newRules = { ...rules, Frequency: currentFrequency };
    setRules(newRules);
  }

  function handleNumberOfVisitsChange(value) {
    const currentNumberOfVisits = {
      ...getNestedProperty(
        rules,
        RULES_NAME.NUMBER_OF_VISITS,
        INITIAL_NUMBER_OF_VISITS
      ),
    };
    if (arguments.length === 2) {
      currentNumberOfVisits.params.numberOfRepeats = parseInt(value);
    } else {
      if(!currentNumberOfVisits.params.numberOfRepeats) {
        currentNumberOfVisits.params.numberOfRepeats = 1;
      }
      currentNumberOfVisits.params.compareString = mapAutoSuggestOptionToValue(
        value
      );
    }
    const newRules = { ...rules, NumberOfVisits: currentNumberOfVisits };
    setRules(newRules);
  }

  function handleNumberOfPagesChange(value) { 

    const currentNumberOfPages = {
      ...getNestedProperty(
        rules,
        RULES_NAME.NUMBER_OF_PAGES_VIEWED,
        INITIAL_NUMBER_OF_PAGES_VIEWED
      ),
    };
    if (arguments.length === 2) {
      currentNumberOfPages.params.numberOfRepeats = parseInt(value);
    } else {
      if(!currentNumberOfPages.params.numberOfRepeats) {
        currentNumberOfPages.params.numberOfRepeats = 1;
      }
      currentNumberOfPages.params.compareString = mapAutoSuggestOptionToValue(
        value
      );
    }
    const newRules = { ...rules, NumberOfPagesViewed: currentNumberOfPages };
    setRules(newRules);
  }

  function handleInviteOnSubmittedChange(value) {

    if(!isNumberPositive(parseInt(value))) {return;}

    const currentInviteOnSubmitted = {
      ...getNestedProperty(
        rules,
        RULES_NAME.DONT_INVITE_ON_SUBMITTED,
        INITIAL_INVITE_ON_SUBMITTED
      ),
    };
    currentInviteOnSubmitted.params.days = parseInt(value);
    const newRules = {
      ...rules,
      DontInviteOnSubmitted: currentInviteOnSubmitted,
    };
    setRules(newRules);
  }

  function handleInviteOnDeclinedChange(value) {

    if(!isNumberPositive(parseInt(value))) {return;}

    const currentInviteOnDeclined = {
      ...getNestedProperty(
        rules,
        RULES_NAME.DONT_INVITE_ON_DECLINED,
        INITIAL_INVITE_ON_DECLINE
      ),
    };
    currentInviteOnDeclined.params.days = parseInt(value);
    const newRules = {
      ...rules,
      DontInviteOnDeclined: currentInviteOnDeclined,
    };
    setRules(newRules);
  }

  const accordionData = useMemo(
    function getAccordionData() {
      const accordionData = [
        {
          title: getSingleText(`${TEXT_PATH}.SessionBasedTargeting`),
          children: (
            <EmbeddedTargetingSession
              rules={rules}
              handleCheckBoxToggle={handleCheckBoxToggle}
              handleFrequencyChange={handleFrequencyChange}
              handleNumberOfVisitsChange={handleNumberOfVisitsChange}
              handleNumberOfPagesChange={handleNumberOfPagesChange}
              handleInviteOnSubmittedChange={handleInviteOnSubmittedChange}
              handleInviteOnDeclinedChange={handleInviteOnDeclinedChange}
              isReadOnlyMode={isReadOnlyMode}
            />
          ),
        },
        {
          title: getSingleText(`${TEXT_PATH}.MetadataBasedTargeting`),
          children: (
            <EmbeddedTargetingMetadata
              rules={rules}
              pageElementID={pageElementID}
              setPageElementID={setPageElementID}
              handleCheckBoxToggle={handleCheckBoxToggle}
              onGenericRuleChange={onGenericRuleChange}
              onTargetDevices={onTargetDevices}
              handleTargetUrls={handleTargetUrls}
              shouldShowIncludedError={shouldShowIncludedError}
              shouldShowExcludedError={shouldShowExcludedError}
              isReadOnlyMode={isReadOnlyMode}
            />
          ),
        }
      ];

      return accordionData;
    },
    [rules, pageElementID]
  );

  return (
    rules && (
      <Fragment>

        <DigTitle additionalDigLabelStyle={additionalDigLabelStyle}  
          digLabel={getSingleText(`${TEXT_PATH}.MetadataHeader`)}
        />

        <Accordion data={accordionData} initialOpenedTab={1}/>
      </Fragment>
    )
  );
}

EmbeddedTargeting.propTypes = {
  formData: PropTypes.object,
  eventTrigger: PropTypes.any,
};

export default withProvider()(
  connect()(EmbeddedTargeting)
);