const mobileIOS = [
    {label: 'Default system font', value: '-apple-system, BlinkMacSystemFont', isDefaultIOSFont: true},
    {label: 'Al Nile', value: 'Al Nile'},
    {label: 'American Typewriter', value: 'American Typewriter'},
    {label: 'Arial Hebrew', value: 'Arial Hebrew'},
    {label: 'Baskerville', value: 'Baskerville'},
    {label: 'Cochin', value: 'Cochin'},
    {label: 'Copperplate', value: 'Copperplate'},
    {label: 'Courier', value: 'Courier'},
    {label: 'Devanagari Sangam MN', value: 'Devanagari Sangam MN'},
    {label: 'Didot', value: 'Didot'},
    {label: 'Euphemia UCAS', value: 'Euphemia UCAS'},
    {label: 'Geeza Pro', value: 'Geeza Pro'},
    {label: 'Georgia', value: 'Georgia'},
    {label: 'Gill Sans', value: 'Gill Sans'},
    {label: 'Gujarati Sangam MN', value: 'Gujarati Sangam MN'},
    {label: 'Gurmukhi MN', value: 'Gurmukhi MN'},
    {label: 'Helvetica', value: 'Helvetica'},
    {label: 'Helvetica Neue', value: 'Helvetica Neue'},
    {label: 'Kailasa', value: 'Kailasa'},
    {label: 'Kannada Sangam MN', value: 'Kannada Sangam MN'},
    {label: 'Malayalam Sangam MN', value: 'Malayalam Sangam MN'},
    {label: 'Myanmar Sangam MN', value: 'Myanmar Sangam MN'},
    {label: 'Oriya Sangam MN', value: 'Oriya Sangam MN'},
    {label: 'Sinhala Sangam MN', value: 'Sinhala Sangam MN'},
    {label: 'Snell Roundhand', value: 'Snell Roundhand'},
    {label: 'Tamil Sangam MN', value: 'Tamil Sangam MN'},
    {label: 'Thonburi', value: 'Thonburi'},
    {label: 'Trebuchet MS', value: 'Trebuchet MS'},
    {label: 'Verdana', value: 'Verdana'},
  ]

  const mobileAndroid = [
    {label: 'ComingSoon', value: 'casual'},
    {label: 'DancingScript-Regular', value: 'cursive'},
    {label: 'DroidSansMono', value: 'monospace'},
    {label: 'Roboto-Regular', value: 'sans-serif', isDefault: true},
    {label: 'Roboto-Black', value: 'sans-serif-black'},
    {label: 'RobotoCondensed-Regular', value: 'sans-serif-condensed'},
    {label: 'RobotoCondensed-Light', value: 'sans-serif-condensed-light'},
    {label: 'Roboto-Light', value: 'sans-serif-light'},
    {label: 'Roboto-Medium', value: 'sans-serif-medium'},
    {label: 'CarroisGothicSC-Regular', value: 'sans-serif-smallcaps'},
    {label: 'Roboto-Thin', value: 'sans-serif-thin'},
    {label: 'NotoSerif-Regular', value: 'serif'},
    {value: 'CutiveMono', value: 'serif-monospace'},
]
  
  export default {
    mobileIOS,
    anywhere: mobileIOS,
    mobileAndroid,
  }