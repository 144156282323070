import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { TriggerContext } from '../../../../../../..';
import TargetingBlockRow from '../../../TargetingBlockRow';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { MultiSelect } from '@m/alchemy-ui';
import { TargetingActions } from '../../../../../Actions';
import { isRuleExists } from '../../../../../../../helpers';

const TargetByOCQRules = () => {
  const { triggerState, triggerDispatch, isReadOnly, hasErrors , setHasErrors } = useContext(TriggerContext);
  const OCQRules = useSelector((state) => state.ruleEngine.OCQRules);
  useEffect(() => {
    isRuleExists(triggerState, 'trigger.rules.OmniChannelRules.id') ? 
      triggerDispatch({ type: TargetingActions.SET_TARGET_BY_OCQ_RULES_IS_ACTIVE , payload : true }) :
      triggerDispatch({ type: TargetingActions.INIT_TARGET_BY_OCQ_RULES , payload : {} } );
  },[]);

  const formatToAlchemyOCQRules = (OCQRules) => {
    return Array.isArray(OCQRules) ? OCQRules.map(({ id, displayName, ...rest }) =>  ({ value: id, label: displayName, ...rest })) : [];
  };

  const unFormatOCQRules = (options) => {
    return options && options.map(({ value , label, ...rest }) =>  ({ id: value , displayName : label , ...rest }));
  };

  const dispatchOCQRule = (options) => {
    const payload = unFormatOCQRules(options);
    triggerDispatch({ type: TargetingActions.SET_TARGET_BY_OCQ_RULES , payload : payload });
    Object.keys(options).length === 0 ? setHasErrors({ ...hasErrors, OmniChannelRules: 'OmniChannelRules' }) : hasErrors && delete hasErrors.OmniChannelRules;
  };

  return (
    <TargetingBlockRow
      checkboxLabel="Add program quarantine rules"
      isFullWidth
      checked={getNestedProperty(triggerState , 'trigger.rules.OmniChannelRules.isActive')}
      disabled={isReadOnly}
      errorMessage={getSingleText(`${TextPathPrefix}.errors.insertValue`)}
      hasError={hasErrors && Object.keys(hasErrors).includes('OmniChannelRules') }
      onCheckboxChange={ (e) => triggerDispatch({ type: TargetingActions.SET_TARGET_BY_OCQ_RULES_IS_ACTIVE , payload : e.target.checked } ) }>
      <MultiSelect
        placeholder="Please select rule"
        menuMaxWidth="250px"
        values={formatToAlchemyOCQRules(getNestedProperty(triggerState , 'trigger.rules.OmniChannelRules.params.rules'))}
        options={formatToAlchemyOCQRules(OCQRules)}
        onChange={(option) => dispatchOCQRule(option)}/>
    </TargetingBlockRow>
  );
};

export default TargetByOCQRules;
