import { getSingleText } from '../../common/utils';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import Actions from '../components/DigLeftMenuContainer/icons/Actions.svg';
import Administration from '../components/DigLeftMenuContainer/icons/Administration.svg';
import Automation from '../components/DigLeftMenuContainer/icons/Automation.svg';
import SmartReply from '../components/DigLeftMenuContainer/icons/SmartReply.svg';
import Forms from '../components/DigLeftMenuContainer/icons/Forms.svg';
import Inbox from '../components/DigLeftMenuContainer/icons/Inbox.svg';
import Reports from '../components/DigLeftMenuContainer/icons/Reports.svg';
import Dashboard from '../components/DigLeftMenuContainer/icons/Dashboard.svg';
import AppRatingIcon from '../components/DigLeftMenuContainer/icons/AppRating.svg';
import { checkProvision } from '../../redux/store/store-helper';

const AppTreeMap = {
  smartReply: 'menu.smartReply',
  userManagement: 'menu.config.userManagement',
  scheduled: 'pages.reports.scheduled',
  formAnalysis: 'pages.reports.formAnalysis',
  pageElements: 'menu.automation.pageElements',
  tagsConfiguration: 'menu.administration.tagConfiguration',
  advancedSettings: 'pages.automation.AdvanceSettings',
  packages: 'menu.administration.packages',
  device: 'menu.reports.device',
  autoForward: 'pages.actions.autoForward',
  forms: 'menu.forms',
  automation: 'pages.automation',
  actions: 'pages.actions',
  appRating: 'pages.appRating',
  resources: 'menu.administration.resources',
  domainsList: 'menu.administration.domainsList',
};

const formsItem = {
  label: getSingleText('app.menu.forms', 'Forms'),
  stateUrls: ['app.pages.forms','app.pages.builder'],
  icon: Forms,
  key: 'forms',
};

const appRating = {
  label: getSingleText('text.app.menu.appRating', 'App Rating'),
  stateUrls: ['app.pages.appRating'],
  icon: AppRatingIcon,
  key: 'appRating',
};

const ActionsItems = [
  {
    label: getSingleText('app.menu.autoForward', 'Real-Time Alerts'),
    stateUrls: ['app.pages.actions.autoForward'],
    key: 'autoForward',
  }
];

const AutomationItems = [
  {
    label: getSingleText('app.menu.ruleEngine', 'Rule Engine'),
    stateUrls: ['app.pages.administration.ruleEngine'],
    key: 'ruleEngine',
  },
  {
    label: getSingleText('app.menu.customParams', 'Custom Parameters'),
    stateUrls: ['app.pages.administration.customParameters'],
    key: 'customParameters',
  },
  {
    label: getSingleText('app.menu.pageElements', 'User Behavior Triggers'),
    stateUrls: ['app.pages.administration.pageElements'],
    key: 'pageElements',
  },
  {
    label: getSingleText('app.menu.resources', 'Resources Library'),
    stateUrls: ['app.pages.administration.resources'],
    key: 'resources',
  }
];

const AdministrationItems = [
  {
    label: getSingleText('app.menu.tagConfiguration', 'Tags Configuration'),
    stateUrls: ['app.pages.config.tagConfiguration'],
    key: 'tagsConfiguration',
  },
  {
    label: getSingleText('app.menu.advancedSettings', 'Advanced Settings'),
    stateUrls: ['app.pages.config.advancedSettings'],
    key: 'advancedSettings',
  },
  {
    label: getSingleText('app.menu.packages', 'Packages'),
    stateUrls: ['app.pages.config.packages'],
    key: 'packages',
  },
  {
    label: getSingleText('app.menu.domainsList', 'Approved Domains'),
    stateUrls: ['app.pages.domainsList'],
    key: 'domainsList',
  }
];

const NewMenuConfig = [
  formsItem,
  {
    label: getSingleText('app.menu.actions', 'Actions'),
    icon: Actions,
    key: 'actionsNew',
    children: [
      ...ActionsItems,
      {
        label: getSingleText('app.menu.smartReply', 'Smart Reply'),
        stateUrls: ['app.pages.smartReply.email'],
        key: 'smartReply',
      }
    ],
  },
  appRating,
  {
    label: getSingleText('app.menu.admin', 'Automation'),
    icon: Automation,
    key: 'automation',
    children: [
      ...AutomationItems,
      ...AdministrationItems
    ],
  }
];

const MenuItems = [
  {
    label: getSingleText('app.menu.dashboard', 'Dashboard'),
    stateUrls: ['app.pages.dashboard'],
    icon: Dashboard,
    key: 'dashboard',
  },
  {
    label: getSingleText('app.menu.inbox', 'Inbox'),
    stateUrls: ['app.pages.messages', 'app.pages.messages.inbox'],
    icon: Inbox,
    key: 'inbox',
  },
  {
    label: getSingleText('app.menu.reports', 'Reports'),
    icon: Reports,
    children: [
      {
        label: getSingleText('app.menu.reportFeedbackActivity', 'Feedback Activity'),
        stateUrls: ['app.pages.reports.feedbackActivity'],
        key: 'feedbackActivity',
      },
      {
        label: getSingleText('app.pages.reports.nps.perFormYAxis', 'Net Promoter Score'),
        stateUrls: ['app.pages.reports.nps'],
        key: 'nps',
      },
      {
        label: getSingleText('app.menu.reportRating', 'Grading'),
        stateUrls: ['app.pages.reports.rating'],
        key: 'rating',
      },
      {
        label: getSingleText('app.menu.reportDevice', 'Device'),
        stateUrls: ['app.pages.reports.device'],
        key: 'device',
      },
      {
        label: getSingleText('app.menu.reportWordCloud', 'Word Cloud'),
        stateUrls: ['app.pages.reports.wordCloud'],
        key: 'wordCloud',
      },
      {
        label: getSingleText('app.menu.formAnalysis', 'Form Analysis'),
        stateUrls: ['app.pages.reports.formAnalysis'],
        key: 'formAnalysis',
      },
      {
        label: getSingleText('app.menu.reportScheduled', 'Scheduled Reports'),
        stateUrls: ['app.pages.reports.scheduled'],
        key: 'scheduled',
      }
    ],
  },
  formsItem,
  appRating,
  {
    label: getSingleText('app.menu.smartReply', 'Smart Reply'),
    stateUrls: ['app.pages.smartReply.email'],
    icon: SmartReply,
    key: 'smartReply',
  },
  {
    label: getSingleText('app.menu.actions', 'Actions'),
    icon: Actions,
    key: 'actions',
    children: ActionsItems,
  },
  {
    label: getSingleText('app.menu.admin', 'Automation'),
    icon: Automation,
    key: 'automation',
    children: AutomationItems,
  },
  {
    label: getSingleText('app.menu.config', 'Configuration'),
    icon: Administration,
    children: AdministrationItems,
  }
];

function shouldDisplayEmptyMenu(role) {
  return checkProvision(DigProvisions.MDEC_FORM_INTEGRATION) && role === UserRolesAsString.ROLE_VIEWER;
}

function shouldShowMenuItem(menuItem, provisions, propertyType, role,appTreeService) {
  //Get the app tree configuration
  const elemPath = AppTreeMap[menuItem.key];
  const appTree = getNestedProperty(window, 'DIG_APP_TREE.appTree');

  if (!elemPath || !appTree) { //No App tree check needed
    return true;
  }

  let elemObj = getNestedProperty(appTree, elemPath);
  if (!elemObj) {
    return false;
  }

  elemObj = elemObj || {};

  //Check property type
  const hasValidPropertyType = appTreeService.checkValidPropertyType(elemObj.propertyTypes, propertyType);
  if (!hasValidPropertyType) {
    return false;
  }

  //Check role
  const hasValidRole = !elemObj.role || !!appTreeService.checkRole(elemObj.role);
  if (!hasValidRole) {
    return false;
  }

  //Check provision
  const provisionString = Array.isArray(elemObj.provision) ? elemObj.provision[0] : elemObj.provision;
  let hasValidProvision = !elemObj.provision || !!appTreeService.isProvisioned(provisionString);
  if (!hasValidProvision) {
    return false;
  }

  return true;
}

function getLeftMenuComponents(provisions, propertyType, role, appTreeService) {
  const ret = [];

  if(shouldDisplayEmptyMenu(role)) {
    return ret;
  }

  let currentMenu = MenuItems;
  if (appTreeService.isProvisioned(DigProvisions.MDEC_FORM_INTEGRATION) &&
    appTreeService.isProvisioned(DigProvisions.UNIFIED_PRODUCT_MENU_WITH_MEC)) {
    currentMenu = NewMenuConfig;
  }
  currentMenu.forEach((menuItem) =>  {
    if (shouldShowMenuItem(menuItem, provisions, propertyType, role, appTreeService)) {
      let newItem = Object.assign({}, menuItem);
      if (menuItem.children) {
        newItem.children = [];

        menuItem.children && menuItem.children.forEach((subItem) => {
          if (shouldShowMenuItem(subItem, provisions, propertyType, role, appTreeService)) {
            newItem.children.push(subItem);
          }
        });
      }
      if (!newItem.children || newItem.children.length > 0) { //Only push if there is no submenu or at least 1 item in the submenu
        ret.push(newItem);
      }
    }
  });

  window.AutoMenuMapping = ret;
  return ret;

}
export { getLeftMenuComponents };
