import React, { useContext } from 'react';
import AlchemyFontList from '../../../../../../../../assets/AlchemyFontList';
import { Select, ColorField } from '@m/alchemy-ui';
import { PanelSubHeader } from '../../../../../../StyledTriggeringModal';
import { TriggerContext } from '../../../../../..';
import { Separator } from '../../../../StyledTriggerByTargeting';
import TargetingField from '../../../../../Shared/TargetingField';
import { TargetingActions } from '../../../../Actions';
import { BANNER_OPTIONS } from '../..';
import { TextPathPrefix } from '../../../..';
import { getSingleText } from '../../../../../../../../../common/utils';
import { BANNER_INITIAL_COLOR_VALUES } from '../../../../../../../../assets/TriggeringModal/InitialFormTriggeringData';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { getPropertyType } from '../../../../../../../../../redux/store/store-helper';
import { convertAlchemyColorFieldToHex } from '../../../../../../../../utils/colorHexaUtils';


const GeneralSettings = () => {
  const { triggerState, triggerDispatch, isReadOnly } = useContext(TriggerContext);
  const propertyType = getPropertyType();

  const formatPositionValue = () => {
    const { triggerCustomBannerContract: { position, isPartial } } = triggerState.trigger;
    const partial = isPartial ? '.PARTIAL' : '';
    return `${position}${partial}`;
  };
  return (
    <React.Fragment>
      <TargetingField
        label="Font"
        layoutVariant="inline"
        disabled={isReadOnly}
        render={
          <Select
            isSearchable
            isFullWidth
            options={AlchemyFontList[propertyType]}
            value={getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.font')}
            onChange={(option) => triggerDispatch({ type: TargetingActions.SET_FONT, payload: option.value })} />
        }
      />
      <TargetingField
        label="Text"
        layoutVariant="inline"
        disabled={isReadOnly}
        render={
          <ColorField
            colorPickerProps={{}}
            variant="hex"
            hasVariantLabel={false}
            defaultColor={BANNER_INITIAL_COLOR_VALUES.textColor}
            value={getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.textColor') || BANNER_INITIAL_COLOR_VALUES.textColor}
            onColorChange={({ color }) => {
              triggerDispatch({ type: TargetingActions.SET_TEXT_COLOR, payload: convertAlchemyColorFieldToHex(color) });
            }}
            stringMap={{}}
          />
        }
      />
      <TargetingField
        label="Background"
        layoutVariant="inline"
        disabled={isReadOnly}
        render={
          <ColorField
            variant="hex"
            hasVariantLabel={false}
            defaultColor={BANNER_INITIAL_COLOR_VALUES.backgroundColor}
            value={getNestedProperty(triggerState, 'trigger.triggerCustomBannerContract.backgroundColor') || BANNER_INITIAL_COLOR_VALUES.backgroundColor}
            onColorChange={({ color }) => {
              triggerDispatch({ type: TargetingActions.SET_BACKGROUND, payload: convertAlchemyColorFieldToHex(color) });
            }}
            stringMap={{}}
          />
        }
      />
      <Separator />
      <PanelSubHeader>{getSingleText(`${TextPathPrefix}.position`)}</PanelSubHeader>
      <TargetingField 
        className="custom-field-margin"
        disabled={isReadOnly}
        render={
          <Select
            value={formatPositionValue()}
            options={BANNER_OPTIONS}
            onChange={(option) => triggerDispatch({ type: TargetingActions.SET_BANNER_POSITION, payload: option.value })}
          />
        }
      />
    </React.Fragment>
  );
};

export default GeneralSettings;