import React, { useEffect, useContext } from 'react';
import { TriggerContext } from '../../../../../../..';
import TargetingBlockRow from '../../../TargetingBlockRow';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { NumberInput, Select } from '@m/alchemy-ui';
import { TargetingActions } from '../../../../../Actions';
import { newTriggerModalComparisonOptions } from '../../../../../../../../../common/dropdownOptions';
import { Subtitle } from '../../../../../../../StyledTriggeringModal';
import { getSingleText } from '../../../../../../../../../../common/utils';
import { TextPathPrefix } from '../../../../..';
import { isRuleExists, formatInputNumberNullValue } from '../../../../../../../helpers';

const TargetByNumberOfSessions = () => {
  const { triggerState, triggerDispatch, isReadOnly, hasErrors , setHasErrors } = useContext(TriggerContext);
  const defaultValue = getNestedProperty(triggerState, 'trigger.rules.MobileNumberOfAppSession.params.numberOfRepeats');
  const [value, setValue] = React.useState(defaultValue || 1);

  useEffect(() => {
    if(isRuleExists(triggerState, 'trigger.rules.MobileNumberOfAppSession.id')){
      triggerDispatch({ type: TargetingActions.SET_TARGET_BY_NUMBER_OF_SESSION_IS_ACTIVE , payload : true });
    }else{
      triggerDispatch({ type: TargetingActions.SET_TARGET_BY_NUMBER_OF_SESSION_IS_ACTIVE , payload : false });
      triggerDispatch({ type: TargetingActions.SET_TARGET_BY_NUMBER_OF_SESSION_COMPARE_STRING , payload : newTriggerModalComparisonOptions[0].value });
    }
  },[]);

  const onNumberChanged = (val) => {
    setValue(val);
    triggerDispatch({ type: TargetingActions.SET_TARGET_BY_NUMBER_OF_SESSION , payload : val });
    !val ? setHasErrors({ ...hasErrors, MobileNumberOfAppSession: 'MobileNumberOfAppSession' }) : hasErrors && delete hasErrors.MobileNumberOfAppSession;
  };

  const handleCheckboxChange = (e) => {
    triggerDispatch({ type: TargetingActions.SET_TARGET_BY_NUMBER_OF_SESSION_IS_ACTIVE , payload : e.target.checked } );
    onNumberChanged(1);
  };

  return (
    <TargetingBlockRow
      checkboxLabel="Users who have visited your app"
      checked={getNestedProperty(triggerState, 'trigger.rules.MobileNumberOfAppSession.isActive')}
      disabled={isReadOnly}
      errorMessage={getSingleText(`${TextPathPrefix}.errors.insertValue`)}
      hasError={hasErrors && Object.keys(hasErrors).includes('MobileNumberOfAppSession') }
      onCheckboxChange={ handleCheckboxChange }>
      <React.Fragment>
        <Select 
          options={newTriggerModalComparisonOptions}
          value={getNestedProperty(triggerState, 'trigger.rules.MobileNumberOfAppSession.params.compareString')}
          onChange={(option) => triggerDispatch({ type: TargetingActions.SET_TARGET_BY_NUMBER_OF_SESSION_COMPARE_STRING , payload : option.value })} />
        <NumberInput
          className='number-of-session-input'
          min="1"
          formatValue={formatInputNumberNullValue}
          value={value}
          onNumberChange={onNumberChanged} />
        <Subtitle marginLeft={1.5}>{getSingleText(`${TextPathPrefix}.rulesTexts.sessions`)}</Subtitle>
      </React.Fragment>
    </TargetingBlockRow>
    
  );
};

export default TargetByNumberOfSessions;
