import React from 'react';
import PropTypes from 'prop-types';
import { bind } from '@mdigital/components/dist/decorators';
import { connect } from 'react-redux';

import './DigLocalizationModalContainer.scss';
import { getSingleText } from '../../../common/utils';

import * as localizationActions from '../../../redux/actions/localization.actions';
import withProvider from '../../hoc/ProviderHoc';
import httpManager from '../../../services/HttpManager';
import DigLocalizationModal from './DigLocalizationModal';
import { convertErrorToErrorMessages } from '../../utils/error';

@withProvider()
@connect(mapStateToProps, mapDispatchToProps)
export default class ModalManager extends React.Component {

  static propTypes = {
    // mapStateToProps
    formId: PropTypes.number,
    propertyId: PropTypes.number,
    fileName: PropTypes.string,
    formLocalizationSettings: PropTypes.object,
    availableLanguages: PropTypes.array,
    localizationHelpLink: PropTypes.string,
    version: PropTypes.string,

    // mapDispatchToProps
    closeModal: PropTypes.func,

    addAlert: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalStatus: {
        hasError: false,
      },
    };
    this.isSaving = false;
  }

  @bind
  handleOkClicked() {
    this.resetModalStatus();

    const url = `kma/api/property/${this.props.propertyId}/engagements/${this.props.formId}/localization`;
    const { availableLanguages, formLocalizationSettings, fileName } = this.props;
    const requestContract = formatStateForUpdateRequest(
      availableLanguages,
      formLocalizationSettings,
      fileName
    );
    if (!this.isSaving) {
      this.isSaving = true;
      httpManager.post(url, requestContract)
        .then(() => {
          this.props.closeModal();
          this.props.addAlert(NOTIFICATION_TYPES.SUCCESS, getSingleText('app.pages.forms.localization.modalSubmitSuccess'));
          this.isSaving = false;
        })
        .catch((err) => {
          this.setState({
            modalStatus: {
              hasError: true,
              errorMessage: {
                digType: 'danger',
                digMessageHeader: `${getSingleText('app.pages.forms.localization.modalSubmitFailed')}`,
                digMessageBody: convertErrorToErrorMessages(err),
              },
            },
          });
          this.isSaving = false;
        });
    }


  }


  @bind
  handleCancelClicked() {
    this.resetModalStatus();
    this.props.closeModal();
  }


  resetModalStatus() {
    this.setState({ modalStatus: { hasError: false } });
  }

  render() {
    const isOkButtonDisabled = !isValidFormLocalizationSettings(this.props.formLocalizationSettings) || this.props.version === 'published';
    const isShown = Boolean(this.props.formId && this.props.propertyId);
    const additionalInfo = {
      label: getSingleText('app.pages.forms.localization.modalHelpLabel'),
      isLink: true,
      url: this.props.localizationHelpLink,
      iconClassName: 'neb-icon-help',
    };

    return (
      <DigLocalizationModal
        isShown={isShown}
        onOkClicked={this.handleOkClicked}
        onCancelClicked={this.handleCancelClicked}
        isOkButtonDisabled={isOkButtonDisabled}
        additionalInfo={additionalInfo}
        modalStatus={this.state.modalStatus}
        addAlert={this.props.addAlert}
      />
    );
  }
}

function formatAvailableLanguagesForRequest(availableLanguages) {

  return Array.isArray(availableLanguages) && availableLanguages.map((lang) => ({
    languageCode: lang.label,
    languageCodeToCopyFrom: lang.duplicated || null,
  }));
}

function formatStateForUpdateRequest(availableLanguages = [], formLocalizationSettings = {}, fileName = '') {
  return {
    fileName: fileName,
    formLocalizationUpdateSettingsContract: {
      languageUpdateContracts: formatAvailableLanguagesForRequest(availableLanguages),
      formLocalizationSettingsContract: formLocalizationSettings,
    },
    advancedFormDataContract: {
      customParametersInUse: (formLocalizationSettings.useCustomParam && formLocalizationSettings.customParam) ? [formLocalizationSettings.customParam] : [],
    },
  };
}

function isValidFormLocalizationSettings(formLocalizationSettings = {}) {
  return !!(formLocalizationSettings.defaultLanguage &&
    (!formLocalizationSettings.useCustomParam || (formLocalizationSettings.useCustomParam && formLocalizationSettings.customParam)));
}

function mapStateToProps(state) {
  const { app, localization, property } = state;
  return {
    propertyId: property.id,
    formId: localization.formId,
    fileName: localization.fileName,
    formLocalizationSettings: localization.formLocalizationSettings,
    availableLanguages: localization.availableLanguages,
    localizationHelpLink: app.helpCenterLinks && app.helpCenterLinks.localization.link,
    version: localization.version,
  };
}

function mapDispatchToProps(dispatch) {
  return ({
    closeModal() {
      return dispatch(localizationActions.resetLocalizationState());
    },
  });
}
