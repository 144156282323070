import React from 'react';
import PropTypes from 'prop-types';
import { getSingleText } from '../../../../../common/utils';
import { ROLE_TYPES } from '../../../../../common/constants';
import { checkProvision, getProfile } from '../../../../../redux/store/store-helper';

const canDetachField = (isProvisionEnabled, hasDetachFn, hasEcKey, userRole) => {
  return Boolean(isProvisionEnabled && hasDetachFn && hasEcKey && userRole ===  ROLE_TYPES.ROLE_ADMIN);
};

export const DetachFieldLink = ({ explicitFieldDetach, baseTextPath, hasEcKey }) => {
  const { userRole } = getProfile();

  const isProvisionEnabled = checkProvision(DigProvisions.DETACH_FROM_EC_FIELD);
  const shouldDisplay = canDetachField(isProvisionEnabled, explicitFieldDetach, hasEcKey, userRole);

  return shouldDisplay && (
    <p className="text-dark">
      {getSingleText(`${baseTextPath}detachFieldLink`)}
      <button 
        type="link" 
        className="btn-link" 
        onClick={explicitFieldDetach}
      >
        {getSingleText(`${baseTextPath}clickHere`)}
      </button>
    </p>
  );
};

DetachFieldLink.propTypes = {
  explicitFieldDetach: PropTypes.func.isRequired,
  baseTextPath: PropTypes.string.isRequired,
  hasEcKey: PropTypes.bool.isRequired,
};

export default React.memo(DetachFieldLink);