//#region imports
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getSingleText } from '../../../../../ROOT/common/utils';
import { checkProvision } from '../../../../redux/store/store-helper';
import ExpandableList from '@mdigital/components/dist/components/ExpandableList';
//#endregion imports


EcOptionsChanged.propTypes = {
  ecFields: PropTypes.object,
};

const StyledDetailsDivWrapper = styled.div`
  display: grid;
  grid-row-gap: 1em;
  margin-left: 1em;
`;

const StyledSpan = styled.span`
  font-size: 14px;
  color: #40440a;
`;

/**
 * About the business case:
 * - This has to do with `Reusable Fields`.
 * - When the user adds options to any form field this modal gets enabled.
 * - The options have to be added from the `MEC`.
 * - The scenario needs to use an integrated MEC property in Nebula.
 */
function EcOptionsChanged({ ecFields }) {
  const getModalText = (label) => (
    getSingleText(`app.pages.forms.builder.tabs.fieldSettings.fieldMappingModal.${label}`)
  );

  const renderError = () => (
    <p>
      {getModalText('errorDisplaying')}<br />
      {getModalText('contactSupport')}
    </p>
  );

  const renderEcOptionsChangedDetails = (labelAndFieldNames) => {
    // eslint-disable-next-line curly
    if (!labelAndFieldNames || !labelAndFieldNames.length) return renderError();

    const fieldBrokenSubLabel = checkProvision(DigProvisions.ENABLE_SURVEY_DRAFTS) 
      ? getModalText('hasToBeDraftOrUnpublished')
      : getModalText('hasToBeUnpublished');
    const questionLabel = (label) => <p> <b> {getModalText('questionLabel')}: </b> { label } </p>;
    const fieldDetails = (data) => {
      if (data.isIntegrationBroken) {
        return (
          <React.Fragment>
            <span>
              <span className="symbol required"/>
              <small><b> {getModalText('fieldBroken')} </b> ({fieldBrokenSubLabel}) </small>
            </span>
            { questionLabel(data.label) }
          </React.Fragment>
        );
      }
      if (data.hasChangedOptions) {
        return (
          <React.Fragment>
            { questionLabel(data.label) }
            <p>
              <b> {getModalText('reusableFieldName')}:</b> {data.reusableFieldName}
            </p>
          </React.Fragment>
        );
      }
    };

    return (
      <StyledDetailsDivWrapper>
        {labelAndFieldNames.map((data, index) => (
          <div key={index}>
            { fieldDetails(data) }
          </div>
        ))}
      </StyledDetailsDivWrapper>
    );
  };

  const renderEcOptionsChanged = () => {
    const pagesName = Object.keys(ecFields);
    // eslint-disable-next-line curly
    if (!pagesName || !pagesName.length) return renderError();

    return (
      <React.Fragment>
        <h4>{getModalText('pleaseReview')}</h4>
        <ExpandableList multipleExpanded={true}>
          {pagesName.map((pageName, index) => {
            const expandableListLabel = `Page "${pageName}"`;
            
            return (
              <ExpandableList.Item key={index} label={expandableListLabel}>
                {renderEcOptionsChangedDetails(ecFields[pageName])}
              </ExpandableList.Item>
            );
          })}
        </ExpandableList>
      </React.Fragment>
    );
  };

  const renderEcIntegrationBroken = () => (
    <StyledSpan>
      {getSingleText('app.pages.forms.builder.tabs.fieldSettings.tooltips.ecFieldDeleted')}
    </StyledSpan>
  );

  return (
    <section>
      { 
        ecFields
          ? renderEcOptionsChanged()
          : renderEcIntegrationBroken()
      }
    </section>
  );
}

export default EcOptionsChanged;